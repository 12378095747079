.unauthorized-layout {
  background: #85812e;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.detail-text {
  margin-left: 2%;
  margin-right: 40%;
}

.detail-text-item {
  margin-bottom: 20px;
  padding-right: 10%;
  font-size: 17px;
  font-weight: bold;
  color: white;
}


@media only screen and (max-width: 1100px) {
  .detail-text {
    margin-right: 50%;
  }
}


@media only screen and (max-width: 1000px) {
  .detail-text {
    margin-right: 60%;
  }
}


@media only screen and (max-width: 900px) {
  .detail-text {
    margin-right: 70%;
  }
}

@media only screen and (max-width: 800px) {
  .detail-text {
   display: none;
  }
}