.ant-modal-body {
  .ant-form-item-label {
    line-height: 18px;
  }
  .ant-form-item {
    margin-bottom: 12px;
  }
  .ant-input-number {
    width: 100%;
  }
}
.fitbit-steps {
  p {
    margin: 3px;
    font-size: 16px;
  }
  strong {
    font-weight: 500;
  }
  .anticon-copy {
    cursor: pointer;
    margin-left: 2px;
  }
}
.top-sapce {
  margin-top: 20px;
}
.copy-wrapper {
  border-top: 1px solid #ccc;
  margin-top: 20px !important;
  padding-top: 12px;
}
.profile-complete-wrapper {
  .ant-btn-primary[disabled] {
    background-color: #ccc !important;
    border-color: #ccc !important;
  }
}

#copy-text {
  border: 0;
  position: absolute;
  left: -200000px;
}