.login-form {
  .signup-msg {
    text-align: center;
    font-size: 17px;
    padding-top: 30px;
    p {
      margin: 5px;
    }
    a {
      font-weight: 400;
    }
  }
  &.ant-form {
    background: #fff;
    padding: 50px;
    min-width: 520px;
    .ant-row {
      &:last-child {
        margin-bottom: 0;
      }
    }
    .ant-form-item-control {
      margin-bottom: 10px;
    }
    .ant-input {
      padding: 20px 25px !important;
      font-size: 18px;
      border-radius: 25px !important;
      font-weight: 300;
    }
    .login-form-forgot {
      float: right;
    }
    .ant-input-affix-wrapper .ant-input-prefix {
      left: 18px;
    }
    .login-form-button {
      width: 100%;
      width: 100%;
      font-size: 15px;
      padding: 8px;
      height: auto;
      border-radius: 24px !important;
    }
    .login-form-button[disabled] {
      color: #fff;
      text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
      box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
    }
    .sh-password {
      color: rgba(0, 0, 0, 0.45);
      cursor: pointer;
      transition: 0.3s;
      &:hover {
        color: rgba(0, 0, 0, 0.7);
        transition: 0.3s;
      }
    }
  }
}
