.mobile-nav {
  display: none;
  .nav-toggle {
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    cursor: pointer;
    height: 36px;
    width: 22px;
    position: fixed;
    top: 8px;
    right: 16px;
    z-index: 2;
    &:hover {
      opacity: 0.8;
    }
    .nav-toggle-bar,
    .nav-toggle-bar::after,
    .nav-toggle-bar::before {
      position: absolute;
      top: 50%;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      -webkit-transition: all 0.5s ease;
      -moz-transition: all 0.5s ease;
      -ms-transition: all 0.5s ease;
      -o-transition: all 0.5s ease;
      transition: all 0.5s ease;
      background: white;
      content: '';
      height: 2px;
      width: 100%;
    }
    .nav-toggle-bar {
      margin-top: 0;
    }

    .nav-toggle-bar::after {
      margin-top: 8px;
    }

    .nav-toggle-bar::before {
      margin-top: -8px;
    }

    &.expanded {
      .nav-toggle-bar {
        background: transparent;
        &::after,
        &::before {
          background: white;
          margin-top: 0;
        }

        &::after {
          -ms-transform: rotate(45deg);
          -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
        }

        &::before {
          -ms-transform: rotate(-45deg);
          -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
        }
      }
    }
  }

  .nav {
    -webkit-transition: right 0.5s ease;
    -moz-transition: right 0.5s ease;
    -ms-transition: right 0.5s ease;
    -o-transition: right 0.5s ease;
    transition: right 0.5s ease;
    background: #00519e;
    color: white;
    cursor: pointer;
    font-size: 16px;
    font-weight: 300;
    padding: 20px 25px;
    position: fixed;
    top: 50px;
    width: 100%;
    right: -100%;
    z-index: 2;
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      li {
        line-height: 36px;
        &.seperator {
          border-top: 1px solid #2e5f9a;
          padding-top: 10px;
          margin-top: 10px;
        }
        &.selected {
          a {
            color: #ffffff;
            font-weight: 400;
          }
        }
      }
      a {
        color: rgba(255, 255, 255, 0.65);
        display: block;
        &:hover,
        &:active {
          color: #ffffff;
        }
      }
    }
    &.expanded {
      right: 0;
    }
  }
}
